import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchRedirectUrl } from "../datasrc/store";

function DefaultRouteHandler() {
  const location = useLocation();

  const [message, messageCb] = useState("Fetching...");

  fetchRedirectUrl(
    location.pathname,
    function(redirectTo) {
      window.location.href = redirectTo!;
    },
    function() {
      messageCb(() => {
        return `No short url found for ${location.pathname}`;
      });
    }
  );

  return (
    <div>
      <h1>{message}</h1>
    </div>
  );
}

export default DefaultRouteHandler;
