import React, { useEffect } from 'react';
import './App.css';
import {NavLink, useLocation} from 'react-router-dom'
import logo from './resources/sgns_logo.png'
import {todos} from './datasrc/store'

function App(props: any) {

  const ABOUT = "/about"
  const PRICING = "/pricing"
  const ROOT = "/"

  const location = useLocation();
  
  function isActive(href: string): string {
    return href === location.pathname ? "active" : ""
  }

  return (

      
      <nav className="navbar navbar-expand-sm navbar-dark bg-primary">
        <a className="navbar-brand" href={ROOT}>
          <img src={logo} className="logo" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarColor01">
          <ul className="navbar-nav mr-auto">
            <li className={`nav-item ` + isActive(ROOT)}>
              <NavLink className="nav-link" to={ROOT}>Home <span className="sr-only">(current)</span></NavLink>
            </li>
            {/* <li className={`nav-item ` + isActive(PRICING)}>
              <NavLink className="nav-link" to={PRICING}>Pricing</NavLink>
            </li> */}
            <li className={`nav-item ` + isActive(ABOUT)}>
              <NavLink activeClassName="active" className="nav-link" to={ABOUT}
              >About</NavLink>
            </li>
          </ul>
        </div>
      </nav>

  );
}



export default App;
