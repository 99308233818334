import * as React from "react";
import { observer } from "mobx-react";
import { Short } from "../../datasrc/store";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";

interface PropsType {
	short: Short;
}

class ShortItem extends React.Component<PropsType, any> {
	private isDeleting: boolean = false;

	public render() {
		const { short } = this.props;
		const { location, redirectTo } = short.data;

		console.log("ShortItem.render: ", short.path, ", text: ", location, redirectTo);
		return (
			<Paper elevation={1}>
				<div className="todo-row">
                    {`${location} ${redirectTo}`}
					
					<IconButton
						className="todo-icon"
						color="secondary"
						onClick={this.onPressDelete}
					>
						<DeleteIcon />
					</IconButton>
				</div>
				<Divider />
			</Paper>
		);
	}

	private onPressDelete = async () => {
		const { short } = this.props;
		if (this.isDeleting) {
			return;
		}
		this.isDeleting = true;
		try {
			await short.delete();
			this.isDeleting = false;
		} catch (err) {
			this.isDeleting = false;
		}
	};

	
}

export default observer(ShortItem);
