import React, { useState } from "react";
import Str from "@supercharge/strings";
import { useHistory } from "react-router-dom";
import isValidHttpUrl from "../util/Util";
import plants from '../resources/plants.png';

function LandingPage() {
  const [urlValue, urlCallback] = useState("");
  const history = useHistory();

  const [isInvalid, isInvalidCallback] = useState("")

  function checkValue(value: string) {
    return value;
  }

  function handleClick(target: React.SyntheticEvent<EventTarget>) {
    target.preventDefault();
    if (urlValue && isValidHttpUrl(urlValue)) {
      history.push({pathname: "/generate", state: { longUrl: urlValue }});
    } else {
      console.log('invalid');
      isInvalidCallback(`is-invalid`)
    }
  }

  return (
    <div className="h-100 plant-image">
    <div className="w-100 mt-5">
      <h3>
        Kijiji ad? Put it on s<span style={{ color: "#ff0000" }}>i</span>
        gns!
      </h3>

      {/* <div className="form-group has-danger">
  <label className="form-control-label" for="inputDanger1">Invalid input</label>
  <input type="text" value="wrong value" className="form-control is-invalid" id="inputInvalid">
  <div className="invalid-feedback">Sorry, that username's taken. Try another?</div>
</div> */}

      <form className="form-inline" onSubmit={handleClick}>
        <div className="mx-auto w-100">
            <div className="has-danger w-100">
          <input
            className={`form-control w-75 mx-auto mt-1 ` + isInvalid}
            type="text"
            placeholder="https://www.kijiji.ca/v-cleaners-cleaning-service/calgary/residential-and-small-office-cleaning-services-calgary-airdrie/1507185304"
            name="urlValue"
            onChange={(e) => urlCallback(e.target.value)}
            value={urlValue}
          />
          &nbsp;
          <button className="btn btn-primary mt-1" type="submit">
            Generate
          </button>
          <br />
          <div className="invalid-feedback">
              Hmm.. doesn't look like a link.
            </div>
          </div>
          
        </div>
      </form>
      {/* <div className="d-flex justify-content-center">
      <div className="col-12 ">
      <img src={plants} className="w-100" />
      </div>
      </div> */}
    </div>
    </div>
  );
}

export default LandingPage;
