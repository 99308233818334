import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import copy from "copy-to-clipboard";
import {
  fetchRedirectUrl,
  fetchRedirectUrl2,
  createNewRecord,
  ShortLinkType,
} from "../datasrc/store";
import { toJS } from "mobx";

type LongUrlState = {
  longUrl?: string;
};

const Generate = () => {
  const randomWords = require("random-words");

  const { state } = useLocation<LongUrlState>();

  const [isValid, isValidCb] = useState("");

  const [twoWords, twoWordsCb] = useState("");

  const [userMade, userMadeCb] = useState("");

  const [isCopied, isCopiedCb] = useState(false);

  const [copyBtnDisabled, copyBtnDisabledCb] = useState(false);
  
  const [userMadeIsValid, userMadeIsValidCb] = useState("")

  const [regBtnTxt, regBtnTxtCb] = useState("Register")

  const [regBtnDisabled, regBtnDisabledCb] = useState(true)

  const [confirm, confirmCb] = useState(false)

  const [userMadeTxtReadOnly, userMadeTxtReadOnlyCb] = useState(false)

  const [regSuccessMsg, regSuccessMsgCb] = useState("The link you have chosen is available. Click confirm above to claim the link.")

  useCallback(generateRandomWords, [twoWords]);

  useEffect(() => {
    generateRandomWords();
  }, []);

  function generateRandomWords() {
    let twoWord = randomWords(2).join("");

    console.log(twoWord);

    fetchRedirectUrl2(`/${twoWord}`).then(({ docs }) => {
      if (docs.length) {
        const t: ShortLinkType = toJS(docs[0].data);
        twoWordsCb(`${twoWord} found`);
        // twoWordsCb("found");
      } else {
        twoWordsCb(twoWord);
      }
    });
  }

  function checkIfAlreadyRegistered() {
    // If the user made link does not yet exist
    if (confirm) {
      console.log('will register now')
      regBtnTxtCb("Registered")
      regBtnDisabledCb(true)
      userMadeTxtReadOnlyCb(true)
      copyGeneratedToClipboard()
      
    }else {
      
      fetchRedirectUrl2(`/${userMade}`).then(({ docs }) => {
        if (docs.length) {
          console.log('already exists')
          userMadeIsValidCb("is-invalid")
          
        } else {
          userMadeIsValidCb("is-valid")
          regBtnTxtCb("Confirm")
          confirmCb(true)

          copyBtnDisabledCb(true)
        }
      });

      
    }
  }

  function invalidateUserMadeCb() {

    userMadeIsValidCb("")
    regBtnDisabledCb(userMade === '')
    
  }

  /* bootstrap popover is weird, but here's how to enable it */
  //   useEffect(() => {
  //     //@ts-ignore
  //     $('[data-toggle="popover"]').popover();
  //   }, []);

  function copyToClipboard() {
    if (state && state.longUrl && !isCopied) {
      // copy(state!.longUrl);

      createNewRecord({
        location: `/${twoWords}`,
        redirectTo: state.longUrl
      })

      copy(`https://sgns.ca/${twoWords}`)

      isCopiedCb(true)
      isValidCb(" is-valid");
    }
  }

  // TODO: Figure out how to pass arguments from functions passed in html components
  /**
   * Copies user-generated text to clipboard
   */
  function copyGeneratedToClipboard() {
    if (state && state.longUrl && !isCopied) {
      // copy(state!.longUrl);

      createNewRecord({
        location: `/${userMade}`,
        redirectTo: state.longUrl
      })

      copy(`https://sgns.ca/${userMade}`)

      isCopiedCb(true)
      regSuccessMsgCb("You may now use the link, and it has been copied to clipboard. Try pasting it in your browser.")
      //isValidCb(" is-valid");
    }
  }

  return (
    <div className="d-flex justify-content-center mt-5">
      <div className="p-2 form-group w-50">
        <div>
          <h5>
            Instead of <small className="muted-text">{state?.longUrl}</small>
          </h5>
        </div>
        <div>
          <span>
            <h5>Use this: </h5>
          </span>
        </div>

        <div className="form-group has-success">
          <div className="input-group mb-3">
            <input
              type="text"
              value={`sgns.ca/${twoWords}`}
              className={`form-control ${isValid}`}
              aria-label="Short url"
              readOnly
            />
            <div className="input-group-append">
              <button
                className="btn btn-md btn-primary"
                onClick={copyToClipboard}
                disabled={copyBtnDisabled}
              >
                Copy
              </button>
            </div>
            <div className="valid-feedback">
              Copied! Try pasting it in your browser.
            </div>
          </div>
        </div>
        <div>
          <span>
            <h5>or make your own:</h5>
          </span>
        </div>
        <div className="form-group">
          <div className="form-group">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">sgns.ca/</span>
              </div>
              <input
                type="text"
                className={`form-control ${userMadeIsValid}`}
                value={userMade}
                onChange={(event) => {userMadeCb(event.target.value); invalidateUserMadeCb()}}
                readOnly={userMadeTxtReadOnly}
              />
              <div className="input-group-append">
                <button className="btn btn-md btn-primary " onClick={checkIfAlreadyRegistered} disabled={regBtnDisabled}>{regBtnTxt}</button>
              </div>
              <div className="valid-feedback">
              {regSuccessMsg}
              </div>
              <div className="invalid-feedback">
                Sorry, that link is taken. Try another?
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Generate;
