import * as firebase from "firebase/app";
import "firebase/firestore";
import { initFirestorter, Collection, Document, Mode } from "firestorter";
import { toJS } from "mobx";

firebase.initializeApp({
  apiKey: "AIzaSyBaBynxt7F63LOs3XGD6ZcKs8Cwc3Ws37U",
  authDomain: "sgns-ca.firebaseapp.com",
  databaseURL: "https://sgns-ca.firebaseio.com",
  projectId: "sgns-ca",
  storageBucket: "sgns-ca.appspot.com",
  messagingSenderId: "503227503579",
  appId: "1:503227503579:web:5d69d0762e383d8d5a0dfe",
  measurementId: "G-SLLJ9Z4GF0",
});

initFirestorter({ firebase });

/**
 * Fetches the pathname's redirectURL from firebase
 * @param pathname
 * @param foundFn callback for
 * @param notFoundFn
 */
function fetchRedirectUrl(
  pathname: string,
  foundFn: (redirectTo?: string) => void,
  notFoundFn: () => void
) {
  // Turn off automatic fetching
  const col = new Collection<Short>("short-urls", {
    mode: Mode.Off,
  });

  col.query = (ref) => ref.where("location", "==", pathname);

  col.fetch().then(({ docs }) => {
    if (docs.length) {
      docs.forEach((doc) => {
        const t: ShortLinkType = toJS(doc.data);
        foundFn(t.redirectTo);
      });
    } else {
      notFoundFn();
    }
  });
}

function fetchRedirectUrl2(pathname: string) {
  // Turn off automatic fetching
  const col = new Collection<Short>("short-urls", {
    mode: Mode.Off,
  });

  col.query = (ref) => ref.where("location", "==", pathname);

  return col.fetch();
}


function createNewRecord(record: ShortLinkType) {
  
  const doc = shorts
    .add(record)
    .then((doc) => {
      console.log("test", doc.id);
    })
    .catch(err => {
      console.log(err)
    });
}

export interface TodoType {
  finished?: boolean;
  text: string;
}

export interface ShortLinkType {
  location: string;
  redirectTo: string;
}

export type Todo = Document<TodoType>;

export type Todos = Collection<Todo>;

export type Short = Document<ShortLinkType>;

export type Shorts = Collection<Short>;

const todos = new Collection<Todo>("todos");

const shorts = new Collection<Short>("short-urls");

export { todos, shorts, createNewRecord, fetchRedirectUrl, fetchRedirectUrl2 };
