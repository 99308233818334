import React from "react"

function About() {
    return (
        <div className="h-100">
            <div className="hero-image h-100 d-flex justify-content-center">

                <div className="w-50">
                    <h3>About</h3>
                <p className="text-left">
                sgns.ca is a URL shortener designed to help you take your online ads offline.

                <br />
                <br />
                Imagine you want to sell your car, you put a sign on the back saying FOR SALE CALL 403 456 7890
                <br />
                <br />
                That's hard to remember.
                <br />
                <br />
                How about FOR SALE sgns.ca/BuyThisCar
                <br />
                <br />
                Better? Of course. It's easy to remember, and when people type that in, you can have that sign redirect to your Kijiji ad.
                <br />
                <br />
                <br />
                </p>

                </div>
            </div>
        </div>
    )
}

export default About