import React from "react";
import ReactDOM from "react-dom";
import "bootswatch/dist/flatly/bootstrap.min.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import DefaultRouteHandler from "./components/DefaultRouteHandler";
import LandingPage from "./components/LandingPage";
import Pricing from "./components/Pricing";
import About from "./components/About";
import Generate from "./components/Generate";
import Todos from "./deleteme/Todos";

import Shorts from "./components/short-urls/Shorts"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <div className="App">
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/pricing">
            <Pricing />
          </Route>
          <Route path="/generate">
            <Generate />
          </Route>
          <Route path="/todos">
            <Todos />
          </Route>
          <Route path="/shorts">
            <Shorts />
          </Route>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route component={DefaultRouteHandler} />
        </Switch>
      </div>
      <footer className="fixed-bottom">&nbsp; 2020 © Bytehala made with React, Netlify, Gitlab, Bootswatch, Firestore, and Humaaans</footer>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
